@font-face {
  font-family: "Rubik-Black";
  src: url("./fonts/Rubik-Black.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: "Rubik-Bold";
  src: url("./fonts/Rubik-Bold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Rubik-SemiBold";
  src: url("./fonts/Rubik-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("./fonts/Rubik-Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("./fonts/Rubik-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

:root {
  --bold: #252a3b !important;
  --toastify-color-info: #466fff;
  --toastify-color-success: #7ac31d;
  --toastify-color-warning: #f4c829;
  --toastify-color-error: #f35f33;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #ffffff;
  margin: 0;
}

a {
  text-decoration: none;
}

textarea {
  resize: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
input {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

input:focus,
input:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: #1f84c5 auto 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.special-label {
  display: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.appForm input,
.appForm textarea {
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  border: 1px solid #e2e6e9;
  margin-bottom: 16px;
}

.appForm .appbtn {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 100px;
}

.appForm textarea {
  height: 200px;
}

.borderRed {
  border-color: #f00;
}

.react-tel-input input:focus {
  box-shadow: none;
  border: 2px solid #1f84c5 !important;
}

.react-tel-input input.form-control {
  outline: none;
  border-radius: 8px;
  border: 1px solid #e2e6e9;
}

button {
  box-shadow: none !important;
  cursor: pointer;
  outline: none !important;
  border: none;
}

.containerr {
  max-width: 1172px;
  margin: 0 auto;
}

.containerr_small {
  max-width: 772px;
  margin: 0 auto;
}

.containerr_small2 {
  max-width: 573px;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) and (min-width: 320px) {
  .containerr,
  .containerr_small,
  .containerr_small2 {
    padding-left: 15px !important;
    padding-right: 15px;
  }
}

.appbtn,
.appbtn2,
.appbtn3,
.formBtn {
  border-radius: 8px;
  font-family: "Rubik-Medium", sans-serif;
  font-size: 16px;
  position: relative;
}

.appbtn {
  padding: 16px 15px;
  background: #1d4a8e;
  color: #ffffff;
}

.appbtn2 {
  padding: 16px 15px;
  background: #f6f6f6;
  color: #6f818f;
}

.appbtn3 {
  padding: 12px 16px;
  background: #1f84c5;
  color: #ffffff;
}

.appbtn44 {
  display: block;
  width: 100%;
  padding: 12px 16px;
  background: #61cc52;
  color: #ffffff;
  margin-bottom: 12px;
}

.appbtn55 {
  display: block;
  width: 100%;
  padding: 12px 16px;
  background: #e2e6e9;
  color: #6f818f;
  margin-bottom: 12px;
}

.appbtn:hover,
.appbtn3:hover {
  color: #ffffff;
}

.userDropDown > div > button {
  padding: 0 !important;
}

.userDropDown > div > button > button {
  width: 105px;
}

.userDropDown > div > button > button > div {
  display: none !important;
}

.userDropDown > div > button > button > span {
  padding: 10px !important;
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Rubik-Regular", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #01304f;
}

.userDropDown:hover > div > button > button > span {
  color: #01304f !important;
}

/* filter modal style start */
.closeMenuMainBack {
  top: 0;
  width: 100%;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  background: #f6f6f6;
  transition: 400ms;
  overflow: hidden;
}

.closeMenuMainBack[data-active="inactive"] {
  opacity: 0;
  display: none;
  z-index: -1;
}

.closeMenuMainBack[data-active="active"] {
  opacity: 0.8;
  display: block;
  z-index: 99998;
  transform: translate(15px, 0);
}

.closeMenuMain {
  top: 0;
  width: 300px;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
  z-index: 99999;
  transition: 400ms;
  padding-bottom: 20px;
}

.closeMenuMain svg {
  cursor: pointer;
}

.svgMain {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  padding-top: 10px;
}

.closeMenuMain[data-active="inactive"] {
  left: -300px;
}

.closeMenuMain[data-active="active"] {
  left: 0;
}

.ulList li {
  width: 100%;
  display: flex;
}

.ulList li a {
  width: 100%;
  font-family: "Rubik-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #01304f;
  border-bottom: 2px solid #1f84c5;
  padding: 10px 5px;
}

.ulList li:nth-last-child(1) a {
  border-bottom: 2px solid transparent;
}

.itemm > button:hover {
  background: #f6f6f6 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.itemm > button {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

/* filter modal style end */

.infoTextt {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.infoTextt span:nth-child(1) {
  margin-right: 8px;
}

.infoTextt span:nth-child(2) {
  padding: 4px 8px;
  border-radius: 12px;
  background: #f6f6f6;
}

.sub1 {
  position: relative;
  z-index: 1;
}

.react-tel-input > .form-control {
  display: block;
  width: 100%;
  border-radius: 8px;
  padding: 28px 24px 28px 50px;
  border: 1px solid #e2e6e9;
  margin-bottom: 16px !important;
}

.dropdown-item > button {
  opacity: 1 !important;
  background-color: transparent !important;
}

/* slick slider */
.slick-active > .custompage::before {
  content: "";
  display: block;
  height: 10px;
  border-radius: 8px;
  width: 0;
  background: #677394;
  animation: progressBar 5000ms linear forwards;
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 50px;
  }
}

.slick-dots .custompage {
  background: #b9bfce;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
